
const getDefaultState = () => {
  return {
    assetArray: [],
    categoryArray: [],
    productArray: [],
    opType: 1
  }
}

const state = getDefaultState()

const productManage = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_asset_array (state, data) {
      state.assetArray = data || []
    },
    set_category_array (state, data) {
      state.categoryArray = data || []
    },
    set_product_array (state, data) {
      state.productArray = data || []
    },
    set_op_type (state, data) {
      state.opType = data || 1
    }
  },
  actions: {

  }
}

export default productManage
